export const environment = {
  logStackTrace: true,
  production: false,
  driveAppID: 8,
  apiVersion: 'v2',
  apiEndpoint: 'https://api.literaxqa.origon.app/api',
  apiVersionMigr: 'v3',
  apiEndpointMigr: 'https://api.literaxqa.origon.app/migr/api',
  guestApiEndpoint: 'https://api.literaxqa.origon.app',
  sentryDsn:
    'https://0c5135eb49204f52ac417f4066243bc6@o288616.ingest.sentry.io/5403254',
  domain: 'https://literaxqa.origon.cloud',
  servicesDomain: 'https://services.literaxqa.origon.app',
  paymentWidgetSource:
    'https://mediosdepagoqa.interfactura.com/script/paymentmanager-wd.js',
  paymentClientId: '79422d23-fcd7-40a2-8032-f7a9e930ca6b',
  paymentCustomerId: 'INT020124V62',
  urlAnalytics: 'https://www.googletagmanager.com/gtag/js?id=',
  analyticsId: 'UA-174567452-2',
  widgetUrl: 'https://gendocsqa.origon.app/web/scripts/w3/gdd-widget.js',
  recordUrl: 'https://services.literaxqa.origon.app/fb/v1',
  driveUrl: 'https://front.literaxqa.origon.app/widget/lit-drive.min.js',
  onlyOfficeUrl:
    'https://texteditor.literaxqa.origon.app/web/js/webcomponent.onlyoffice.min.js',
  sendToMailSuggestions: 'buzonqa@mailinator.com',
  chatUrl: 'https://front.literaxqa.origon.app/chat_element/lit-chat.min.js',
  literax: 'https://www.literax.com/',
  privacy: 'https://www.literax.com/aviso-de-privacidad/',
  terms: 'https://www.literax.com/terminos-y-condiciones/',
  b2cConfig: {
    clientId: 'fc9c04b6-cd15-4d82-9395-545db7588cdd',
    authority:
      'https://loginstage.origon.cloud/qaidp.origon.cloud/B2C_1A_signup_signin/',
    knownAuthorities: ['https://loginstage.origon.cloud'],
    scopes: [
      'https://qaidp.origon.cloud/fc9c04b6-cd15-4d82-9395-545db7588cdd/basic',
      'openid',
      'offline_access',
    ],
  },
  literaxUrl: 'https://www.literax.com',
  onboarding365JoinQueryKey: 'azb2Data',
  captchaKey: '6LfAZ58lAAAAABiKXFFC3O5zschlA5Luij9X58wO',
  enableHotjarTracking: true,
  hotjarVersion: 6,
  hotjarSiteId: 3534844,
  paymentWidgetGetnetConfig:{
    url: "https://pruebasjuno.interfactura.com/Banks.Widget",
    b2cCredentials:{
      url: "https://qagdxecosystem.b2clogin.com/qaidp.origon.cloud/B2C_1A_ROPC_Auth/oauth2/v2.0/token",
      user: "literax.qa.integration@origon.io",
      pwd: "cJsBeO6rU9CzzGD",
      grant_type: "password",
      scope: "openid https://qaidp.origon.cloud/79b26a0b-31e6-4e5c-b9e9-48f79ba1b147/basic offline_access",
      client_id: "79b26a0b-31e6-4e5c-b9e9-48f79ba1b147",
      response_type: "token id_token"
    }
  }
};
